// store/index.js
import { createStore } from 'vuex';
import { setAccessToken } from '../services/axios';
import subscriptionModule from './subscription/subscription.module';
import recrutierModule from './user/recrutier.module';
import UserModule from './user/user.module';

export default createStore({
  modules: {
    recrutierModule: recrutierModule,
    userModule: UserModule,
    subscription: subscriptionModule,
  },
  state: {
    isLoggedIn: JSON.parse(localStorage.getItem('isLoggedIn')) || false,
    token: localStorage.getItem('access_token') || null,
    receivedInvitationsCount: 0,
  },
  mutations: {
    // isLoggedIn
    setLoggedIn(state, value) {
      state.isLoggedIn = value;
      localStorage.setItem('isLoggedIn', JSON.stringify(value));
    },
    setGoogleConnected(state,value) {
      state.isGoogle = value;
      localStorage.setItem('isGoogle', value);
    },
    setToken(state, token) {
      state.token = token;
      localStorage.setItem('access_token', token);
    },
    setToken(state, token) {
      state.token = token;
      localStorage.setItem('token', token);
    },
    resetUser(state) {
      state.userModule.user = {};
      localStorage.removeItem('access_token');
    },
    // alert
    updateAlert(state, payload) {
      state.userModule.user.alerte = [...state.userModule.user.alerte, payload];
    },
    deleteAlert(state, payload) {
      state.userModule.user.alerte = state.userModule.user.alerte.filter(
        (alerte) => alerte.id !== payload.id
      );
    },
    // candidature
    updateCandidature(state, payload) {
      state.userModule.user.postulation = [
        ...state.userModule.user.postulation,
        payload,
      ];
    },
    // conversation
    addConversation(state, payload) {
      state.userModule.user.conversation = [
        ...state.userModule.user.conversation,
        payload,
      ];
      state.userModule.user.conversation[
        state.userModule.user.conversation.length - 1
      ].messages = [];
    },
    updateConversation(state, payload) {
      const conversationIndex = state.userModule.user.conversation.findIndex(
        (conversation) => conversation.conversation_id === payload.conversation
      );
      if (conversationIndex !== -1) {
        state.userModule.user.conversation[conversationIndex].messages = [
          ...state.userModule.user.conversation[conversationIndex].messages,
          payload,
        ];
      }
    },
    updateLlmConversation(state, payload) {
      const conversationIndex = state.userModule.user.conversation.findIndex(
        (conversation) =>
          conversation.conversation_id === payload.conversation_id
      );
      if (conversationIndex !== -1) {
        delete payload.messages[payload.messages.length - 1].message_applicant;
        state.userModule.user.conversation[conversationIndex].messages = [
          ...state.userModule.user.conversation[conversationIndex].messages,
          payload.messages[payload.messages.length - 1],
        ];
      }
    },
    updateTitleConversation(state, payload) {
      const conversationIndex = state.userModule.user.conversation.findIndex(
        (conversation) =>
          conversation.conversation_id === payload.conversation_id
      );
      if (conversationIndex !== -1) {
        state.userModule.user.conversation[conversationIndex].titre =
          payload.titre;
      }
    },
    deleteConversation(state, payload) {
      const conversationIndex = state.userModule.user.conversation.findIndex(
        (conversation) =>
          conversation.conversation_id === payload.conversation_id
      );
      if (conversationIndex !== -1) {
        state.userModule.user.conversation.splice(conversationIndex, 1);
      }
    },
    SetReceivedInvitationsCount(state, count) {
      state.receivedInvitationsCount = count;
    },
  },
  actions: {
    setToken({ commit }, token) {
      commit('setToken', token);
    },
    // logout
    logout({ commit }) {
      setAccessToken(null);
      commit('setLoggedIn', false);
      commit('resetUser');
    },
    handleLoggedIn({ commit }, value) {
      commit('setLoggedIn', value);
      localStorage.setItem('isLoggedIn', JSON.stringify(value));
    },
    // alert
    handleAlertChange({ commit }, payload) {
      commit('updateAlert', payload);
    },
    handleDeleteAlert({ commit }, payload) {
      commit('deleteAlert', payload);
    },
    // candidature
    handleCandidatureChange({ commit }, payload) {
      commit('updateCandidature', payload);
    },
    // conversation
    handleAddConversation({ commit, state }, payload) {
      commit('addConversation', payload);
    },

    handleConversationChange({ commit }, payload) {
      commit('updateConversation', payload);
    },
    handleLlmConversationChange({ commit }, payload) {
      commit('updateLlmConversation', payload);
    },
    handleTitleConversationChange({ commit }, payload) {
      commit('updateTitleConversation', payload);
    },
    handleDeleteConversation({ commit }, payload) {
      commit('deleteConversation', payload);
    },
    updateReceivedInvitationsCount({ commit }, count) {
      commit('SetReceivedInvitationsCount', count);
    },
  },
  getters: {
    isLoggedIn: (state) => state.isLoggedIn,
    isGoogle: (state) => state.isGoogle,
    token: (state) => {
      return state.token;
    },
    getConversations(state) {
      return state.userModule.user.conversation;
    },
    receivedInvitationsCount: (state) => state.receivedInvitationsCount,
  },
});
