import store from '@/store';
import { toaster } from '@/utils/toast/toast.js';
import { axiosInstance } from './axios';

/**
 * Récupérer les informations utilisateur par api
 * @return {Object}
 */
const getUser = async () => {
  try {
    const user = await axiosInstance.get('/user/');
    return {
      id: user.data.id,
      first_name: user.data.first_name,
      last_name: user.data.last_name,
      adress: user.data.adress,
      code_postal: user.data.code_postal,
      ville: user.data.ville,
      company: user.data.company,
      siret: user.data.siret,
      email: user.data.email,
      abonnement: user.data.abonnement,
      type_user: user.data.type_user,
    };
  } catch (error) {
    throw new Error(error);
  }
};

/**
 * Mettre à jour les informations de l'utilisateur en base de données et dans le store Vuex
 * @param {Object} data - Les nouvelles informations utilisateur
 * @return {void}
 */
const updateUserInformations = async (data) => {
  try {
    // Mettre à jour les informations utilisateur dans Vuex via l'action handleUserChange
    store.dispatch('handleUserChange', { type: null, payload: data });

    // Préparer les nouvelles informations pour l'API
    const newUserInfos = {
      first_name: data.first_name,
      last_name: data.last_name,
      adress: data.adress,
      code_postal: data.code_postal,
      ville: data.ville,
      company: data.company,
      siret: data.siret,
      email: data.email,
    };
    // Appel API pour mettre à jour les informations utilisateur
    await axiosInstance.put('/user/', newUserInfos);

    // Notification de succès
    toaster.showSuccessPopup('Informations sauvegardées.');
  } catch (error) {
    console.error('Erreur lors de la sauvegarde des données.', error);
    // Notification d'erreur
    toaster.showErrorPopup('Erreur lors de la sauvegarde des données.');
  }
};

/**
 * Récupérer les données de l'utilisateur depuis l'API et les stocker dans Vuex
 */
const fetchUserProfile = async () => {
  try {
    // Appel API pour récupérer les données utilisateur
    const response = await axiosInstance.get(/user/);
    const userData = response.data;

    // Mise à jour du store Vuex avec les données utilisateur
    store.dispatch('handleUserChange', {
      type: null,
      payload: {
        first_name: userData.first_name,
        last_name: userData.last_name,
        adress: userData.adress,
        code_postal: user.data.code_postal,
        ville: user.data.ville,
        company: userData.company,
        siret: userData.siret,
        email: userData.email,
        abonnement: user.data.abonnement,
        type_user: user.data.type_user,
      },
    });
  } catch (error) {
    console.error(
      'Erreur lors de la récupération des données utilisateur.',
      error
    );
  }
};

/**
 * Récupérer tous les abonnements depuis l'API
 */
const fetchSubscriptions = async () => {
  try {
    const response = await axiosInstance.get('/facture/type_produit');
    return response.data.results;
  } catch (error) {
    console.error('Erreur lors de la récupération des abonnements.', error);
    toaster.showErrorPopup('Erreur lors de la récupération des abonnements.');
  }
};

const addSubscriptionToStore = async () => {
  try {
    store.commit('subscription/setActiveSubscription', subscription);
  } catch {
    store.commit('subscription/setActiveSubscription', null);
  }
};

const submitStripeSubscription = async (stripeId) => {
  try {
    const response = await axiosInstance.post('/create-checkout-session/', {
      stripe_id: stripeId,
    });
    return response.data.url;
  } catch (error) {
    console.error('Erreur lors du paiement :', error);
    throw error;
  }
};

const getLastFactures = async () => {
  try {
    const response = await axiosInstance.get('/facture/');
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la création de la facture :', error);
    throw error;
  }
};

const createFacture = async (factureData) => {
  try {
    const response = await axiosInstance.post('/facture/', factureData);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la création de la facture :', error);
    throw error;
  }
};

export {
  addSubscriptionToStore,
  fetchSubscriptions,
  fetchUserProfile,
  getUser,
  submitStripeSubscription,
  updateUserInformations,
  getLastFactures,
  createFacture,
};
