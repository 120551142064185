import { axiosInstance } from './axios';

/**
 * Recherche d'offres d'emploi par titre et ville
 * @param {Object} formData - Données de recherche (title et ville)
 * @return {Promise} - Résultat de la recherche
 * @throws {Error} - En cas d'erreur
 */
const searchJobByCityAndTitle = async (formData) => {
  try {
    const params = {
      title: formData.title || '',
      ville: formData.city || '', // Ville est optionnel
    };

    const response = await axiosInstance.get(`/recherche/jobsearch/`, {
      params: params,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error details:', error.response || error);
    throw new Error('Job search by title and city failed');
  }
};

/**
 * Recherche d'offres d'emploi avec tous les filtres
 * @param {Object} formData - Données des filtres (contrat, secteur, télétravail, localisation, salaire)
 * @return {Promise} - Résultat de la recherche
 * @throws {Error} - En cas d'erreur
 */
const searchJobWithFilters = async (formData) => {
  try {
    const params = {
      ...formData,
    };
    // Filtrage par contrat (si applicable)
    if (formData.contract && formData.contract.length > 0) {
      params.contract = formData.contract.join(',');
    }

    // Filtrage par secteur (si applicable)
    if (formData.sector && formData.sector.length > 0) {
      params.sector = formData.sector.join(',');
    }

    // Filtrage par télétravail (si applicable)
    if (formData.remote && formData.remote.length > 0) {
      params.remote = formData.remote.join(',');
    }

    // Filtre par distance (localisation)
    if (formData.locationValue) {
      params.max_distance = formData.locationValue; // Distance maximale sélectionnée
    }

    // Filtre par salaire
    /* if (formData.salaire_min != null && formData.salaire_max != null) {
      params.salaire_min = formData.salaire_min;
      params.salaire_max = formData.salaire_max;
    } else {
      console.warn(
        'Valeurs de salaire manquantes:',
        formData.salaire_min,
        formData.salaire_max
      );
    } */
    const response = await axiosInstance.get(`/recherche/jobsearch/`, {
      params: params,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error details:', error.response || error);
    throw new Error('Job search with filters failed');
  }
};

/**
 * getJobTitlesList
 * @param {void}
 * @return {Promise}
 * @throws {Error}
 */
const getJobTitlesList = async () => {
  try {
    const response = await axiosInstance.get(`/recherche/utilisateur-flexible`);
    console.log(response.data);
    return response.data;
  } catch (error) {
    throw new Error('Job title list download failed');
  }
};
const getJobOffers = async () => {
  try {
    const response = await axiosInstance.get(`/jobs_author/`);
    return response.data;
  } catch (error) {
    throw new Error('Job title list download failed');
  }
};
/**
 *
 * @param {int} jobId
 * @returns liste de candidates de l'offre
 */
const getJobOffersById = async (jobId) => {
  try {
    const response = await axiosInstance.get(`/apply/${jobId}`);

    if (response.data && response.data.postulants) {
      return response.data;
    } else {
      console.warn('Aucun postulant trouvé pour cette offre.');
      return { postulants: [] };
    }
  } catch (error) {
    console.error("Échec de téléchargement des détails de l'offre:", error);
    throw new Error('Job title list download failed');
  }
};
// TODO : cette fonction c'est la meme que celle ci-dessus... à revoir
const getCandidatesForOffer = async (offerId) => {
  try {
    const response = await axiosInstance.get(`/apply/${offerId}/`);
    return response.data;
  } catch (error) {
    console.error(
      `Erreur lors de la récupération des candidats pour l'offre ${offerId}:`,
      error
    );
    throw error;
  }
};

/**
 *
 * @param {int} jobId
 * @returns jobOffer
 */
const getJobOfferById = async (jobId) => {
  try {
    const response = await axiosInstance.get(`/jobs/${jobId}`);
    if (response.data) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Échec de téléchargement des détails de l'offre:", error);
    throw new Error('Job title list download failed');
  }
};

const getDetailCandidateForOffer = async (offerId, candidateId) => {
  try {
    const response = await axiosInstance.get(
      `/apply/detail/${offerId}/${candidateId}`
    );
    return response.data;
  } catch (error) {
    console.error(
      `Erreur lors de la récupération des candidats pour l'offre ${offerId}:`,
      error
    );
    throw error;
  }
};

const acceptCandidateStatus = async (offerId, candidateId) => {
  try {
    const response = await axiosInstance.post(
      `/apply/accepter/${offerId}/${candidateId}/`
    );
    return response.data;
  } catch (error) {
    console.error(
      'Erreur de mise à jour du statut du candidat:',
      error.response || error.message || error
    );
    throw new Error('Erreur de mise à jour du statut du candidat.');
  }
};
const refuseCandidateStatus = async (offerId, candidateId) => {
  try {
    const response = await axiosInstance.post(
      `/apply/mail_refus/${offerId}/${candidateId}/`
    );
    return response.data;
  } catch (error) {
    throw new Error('Erreur de mise à jour du statut du candidat:', error);
  }
};
const holdCandidateStatus = async (offerId, candidateId) => {
  try {
    const response = await axiosInstance.post(
      `/apply/peut_etre/${offerId}/${candidateId}/`
    );
    return response.data;
  } catch (error) {
    throw new Error('Erreur de mise à jour du statut du candidat:', error);
  }
};
// À METTRE UNE FOIS LE BACKEND COMPLÉTÉ
const cancelCandidateStatus = async (offerId, candidateId) => {
  try {
    const response = await axiosInstance.post(
      `/apply/canceled/${offerId}/${candidateId}/`
    );
    return response.data;
  } catch (error) {
    throw new Error('Erreur de mise à jour du statut du candidat:', error);
  }
};

/**
 * Recherche d'offres d'emploi par ville et metier
 * @param {Object} formData - Données de recherche (ville, metier, contrat, last_name, first_name, teletravail)
 * @return {Promise} - Résultat de la recherche
 * @throws {Error} - En cas d'erreur
 */
const searchCandidates = async (formData) => {
  const { metier, ville, contrat, last_name, first_name, teletravail } =
    formData;
  try {
    const response = await axiosInstance.get(
      `/recherche/utilisateur-flexible/?ville=${ville}&metier=${metier}&contrat=${contrat}&last_name=${last_name}&first_name=${first_name}&teletravail=${teletravail}`
    );
    return response.data;
  } catch (error) {
    throw new Error('Candidate search failed', error);
  }
};

// TODO: Cette function est inutile avec la nouvelle methode de recherche de candidates. A revoir...
function getActiveFilters(filters) {
  const activeFilters = {};
  for (const [filterCategory, filterValues] of Object.entries(filters)) {
    if (Array.isArray(filterValues)) {
      // Vérifie si filterValues est un tableau
      activeFilters[filterCategory] = filterValues;
    } else if (typeof filterValues === 'object' && filterValues !== null) {
      activeFilters[filterCategory] = Object.keys(filterValues).filter(
        (key) => filterValues[key]
      );
    } else if (filterValues) {
      activeFilters[filterCategory] = filterValues;
    }
  }
  return activeFilters;
}
// TODO: Cette function est inutile avec la nouvelle methode de recherche de candidates. A revoir...
function filterCandidates(candidates, filters) {
  console.log(
    'cityCoordinates (fonction filterCandidates):',
    filters.cityCoordinates
  );
  console.log(
    'distanceValue:(fonction filterCandidates)',
    filters.distanceValue
  );
  const filteredCandidates = candidates.filter((candidate) => {
    // Filtrer par type de contrat
    if (filters.contract && filters.contract.length > 0) {
      if (!filters.contract.includes(candidate.contrat)) {
        return false;
      }
    }

    // Filtrer par télétravail
    if (filters.remote && filters.remote.length > 0) {
      if (!filters.remote.includes(candidate.teletravail)) {
        return false;
      }
    }

    // Filtrer par secteur d'activité
    if (filters.sector && filters.sector.length > 0) {
      if (!filters.sector.includes(candidate.secteur)) {
        return false;
      }
    }

    // Filtrer par salaire
    if (filters.salary && filters.salary.length === 2) {
      const [minSalary, maxSalary] = filters.salary;

      if (
        candidate.salaire_souhaite < minSalary ||
        candidate.salaire_souhaite > maxSalary
      ) {
        return false;
      }
    }

    // Filtrer par distance
    if (
      Array.isArray(filters.cityCoordinates) &&
      filters.cityCoordinates.length === 2
    ) {
      const [searchLat, searchLon] = filters.cityCoordinates;
      const candidateLat = candidate.latitude;
      const candidateLon = candidate.longitude;
      console.log('searchLat', searchLat);
      console.log('searchLon', searchLon);
      console.log('candidateLat', candidateLat);
      console.log('candidateLon', candidateLon);
      // Calculer la distance entre la ville de recherche et le candidat
      const distance = calculateDistance(
        searchLat,
        searchLon,
        candidateLat,
        candidateLon
      );

      console.log('distance', distance);

      if (distance > filters.distanceValue) {
        return false; // Filtrer si la distance dépasse la limite
      }
    }

    // Assurez-vous de vérifier les critères de métier et de ville également
    if (filters.title) {
      if (
        candidate.metier &&
        candidate.metier.toLowerCase() !== filters.title
      ) {
        return false;
      }
    }

    if (filters.city) {
      if (candidate.ville && candidate.ville.toLowerCase() !== filters.city) {
        return false;
      }
    }

    return true; // Tous les critères sont satisfaits
  });

  return filteredCandidates;
}

// Fonction pour calculer la distance entre deux points (latitude/longitude) en kilomètres
function calculateDistance(lat1, lon1, lat2, lon2) {
  const R = 6371; // Rayon de la Terre en km
  const dLat = ((lat2 - lat1) * Math.PI) / 180;
  const dLon = ((lon2 - lon1) * Math.PI) / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
}

export {
  acceptCandidateStatus,
  calculateDistance,
  filterCandidates,
  getActiveFilters,
  getCandidatesForOffer,
  getDetailCandidateForOffer,
  getJobOfferById,
  getJobOffers,
  getJobOffersById,
  getJobTitlesList,
  holdCandidateStatus,
  refuseCandidateStatus,
  cancelCandidateStatus,
  searchCandidates,
  searchJobByCityAndTitle,
  searchJobWithFilters,
};
