import {
  fetchSubscriptions as fetchSubscriptionsAPI,
  createFacture as createFactureAPI,
  getLastFactures,
} from '@/services/subscription.service.js';

export default {
  namespaced: true,
  state: {
    subscriptions: [],
    activeSubscription: null,
    factures: [],
  },
  mutations: {
    setSubscription(state, subscriptions) {
      state.subscriptions = subscriptions;
    },
    setActiveSubscription(state, subscription) {
      state.activeSubscription = subscription;
    },
    updateSubscription(state, { type, payload }) {
      const index = state.subscriptions.findIndex((sub) => sub.type === type);
      if (index !== -1) {
        state.subscriptions[index] = {
          ...state.subscriptions[index],
          ...payload,
        };
      } else {
        state.subscriptions.push(payload);
      }
    },
    resetSubscription(state) {
      state.subscriptions = [];
      state.activeSubscription = null;
    },
    deleteSubscriptionProperties(state, { type, payload }) {
      state.subscriptions = state.subscriptions.filter(
        (sub) => sub.type !== type || sub.id !== payload.id
      );
    },
    set_facture(state, facture) {
      state.facture = facture;
    },
  },
  actions: {
    handleSubscriptionChange({ commit }, { type, payload }) {
      commit('updateSubscription', { type, payload });
    },
    handleDeleteSubscriptionProperties({ commit }, { type, payload }) {
      commit('deleteSubscriptionProperties', { type, payload });
    },
    async fetchSubscriptions({ commit }) {
      try {
        const subscriptions = await fetchSubscriptionsAPI();
        console.log('subscriptions', subscriptions);
        commit('setSubscription', subscriptions);
      } catch (error) {
        console.error(
          'Erreur lors de la récupération des abonnements :',
          error
        );
        throw error;
      }
    },
    async createFacture({ commit }, factureData) {
      try {
        const response = await createFactureAPI(factureData);
        commit('set_facture', response);
        return response;
      } catch (error) {
        console.error('Erreur lors de la création de la facture :', error);
        throw error;
      }
    },
    async fetchFactures({ commit }) {
      try {
        const response = await getLastFactures();
        commit('set_facture', response);
        return response;
      } catch (error) {
        console.error('Erreur lors de la récupération des factures :', error);
        throw error;
      }
    },
  },
  getters: {
    getSubscription: (state) => state.subscriptions,
    getActiveSubscription: (state) => {
      return state.activeSubscription;
    },
    getFacture(state) {
      return state.facture;
    },
  },
};
